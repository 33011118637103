import React from "react"

import BlogPostPreview from "../../components/BlogPostPreview"

const BlogRelatedPosts = props => {
  const { shuffledBlogPosts } = props

  return (
    <>
      <h2 className="text-xl font-semibold">
        Alguns outros posts que você pode gostar
      </h2>

      <div className="flex flex-wrap">
        {shuffledBlogPosts.length > 0 ? (
          shuffledBlogPosts.map(blogPost => {
            return (
              <div
                className="w-full sm:w-1/2 lg:w-1/3 p-3 md:p-1"
                key={blogPost.slug}
              >
                <BlogPostPreview {...blogPost} hidePublishDate={true} />
              </div>
            )
          })
        ) : (
          <p>No projects found.</p>
        )}
      </div>
    </>
  )
}

export default BlogRelatedPosts
