import { graphql } from "gatsby"
import React from "react"

import { Disqus } from "gatsby-plugin-disqus"
import { GatsbyImage } from "gatsby-plugin-image"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import SiteMetadata from "../components/SiteMetadata"
import PageFooter from "../components/PageFooter"
import BlogPostSidebar from "../components/blogPost/BlogPostSidebar"
import Layout from "../layouts/Layout"
import BlogRelatedPosts from "../components/blogPost/BlogRelatedPosts"

const BlogPost = props => {
  const { title, summary, publishDate, body, id, image } = props.data.blogPost
  const { assets, author, portfolio, allBlogPosts } = props.data

  const allBlogPostNodes = allBlogPosts.nodes
  const shuffledBlogPosts = allBlogPostNodes
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)

  const portfolioItems = portfolio.nodes
  const shuffledPortfolioItems = portfolioItems
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)

  const date = new Date(publishDate)

  const documentToReactOptions = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="font-semibold text-3xl mb-8 mt-8">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="font-semibold text-2xl mb-8 mt-8">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="font-semibold text-xl mb-8 mt-8">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="font-semibold text-xl mb-8 mt-8">{children}</h4>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <h5 className="font-semibold text-xl mb-8 mt-8">{children}</h5>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <h6 className="font-semibold text-xl mb-8 mt-8">{children}</h6>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="mt-4 mb-4 ml-10 list-disc">{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className="mt-4 mb-4 ml-10 list-decimal">{children}</ol>
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <p className="italic bg-gray-200 px-4 py-px">{children}</p>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const contentfulId = node.data.target.sys.id
        const imageField = assets.nodes.find(
          image => image.contentful_id === contentfulId
        )

        if (imageField) {
          return (
            <GatsbyImage
              image={imageField.gatsbyImageData}
              alt={imageField.title}
            />
          )
        }
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="mb-8 mt-8">{children}</p>
      ),
    },
    renderMark: {},
  }

  let disqusConfig = {
    identifier: id,
    title: title,
  }

  return (
    <Layout>
      <SiteMetadata
        title={title}
        description={summary}
        image={image.gatsbyImageData.images.fallback.src}
      />
      <div>
        <div className="container py-12 lg:pb-16">
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 xl:w-9/12 md:pl-12">
              <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-2 text-center">
                {title}
              </h1>
              <p className="text-m leading-tight tracking-tight text-gray-600 mb-12 text-center italic">
                Publicado em {date.toLocaleDateString("pt-BR")}
              </p>
              <div className="flex flex-wrap">
                <div className="w-full text-justify">
                  {documentToReactComponents(
                    JSON.parse(body.raw),
                    documentToReactOptions
                  )}
                </div>
              </div>
              <Disqus config={disqusConfig} />
              <div className="mt-8">
                <BlogRelatedPosts
                  shuffledBlogPosts={shuffledBlogPosts.slice(0, 3)}
                />
              </div>
            </div>
            <BlogPostSidebar
              author={author}
              shuffledPortfolioItems={shuffledPortfolioItems}
            />
          </div>
        </div>
      </div>
      <div className="py-12 px-4 lg:py-16">
        <PageFooter />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    blogPost: contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      body {
        raw
      }
      slug
      publishDate
      image {
        id
        gatsbyImageData(layout: FIXED)
        title
      }
      summary
    }
    assets: allContentfulAsset {
      nodes {
        gatsbyImageData(layout: CONSTRAINED)
        contentful_id
        id
        title
      }
    }
    author: file(relativePath: { eq: "perfil.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          height: 600
          quality: 85
          layout: CONSTRAINED
        )
      }
    }
    portfolio: allContentfulPortfolio(
      sort: { fields: createdAt, order: DESC }
      filter: { node_locale: { eq: "pt-BR" } }
    ) {
      nodes {
        ...PortfolioCard
      }
    }
    allBlogPosts: allContentfulBlogPost(
      filter: { node_locale: { eq: "pt-BR" } }
    ) {
      nodes {
        node_locale
        slug
        image {
          gatsbyImageData(layout: CONSTRAINED)
          title
        }
        title
      }
    }
  }
`

export default BlogPost
