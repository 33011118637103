import React from "react"

import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const BlogPostSidebar = props => {
  const { author, shuffledPortfolioItems } = props

  return (
    <div className="w-full md:w-1/2 xl:w-3/12 px-4 md:pb-0">
      <div className="ml-4 h-full bg-slate-100">
        <div className="p-8">
          <GatsbyImage
            image={author.childImageSharp.gatsbyImageData}
            alt="Pri Bellafronte"
            className="shadow-md rounded-full"
          />
        </div>
        <div className="mx-auto text-center">
          <h2 className="text-xl mt-4 font-bold">Sobre Pri Bellafronte</h2>
          <p className="p-4 text-center">
            Sou Designer Gráfico, especialista em design de identidade visual.
            Crio marcas e logos para pequenas e médias empresas, de forma 100%
            artesanal. Meus trabalhos buscam expressar significado,
            representando a essência de seu negócios ou de sua organização.
          </p>
          <Link
            className={
              "border-2 border-black text-center w-full md:w-auto inline-block text-lg sm:text-lg font-medium " +
              "p-3 border-transparent hover:bg-gray-700 hover:text-slate-100 " +
              "hover:border-gray-800 hover:border-2 hover transition duration-150 " +
              "ease-in-out background-transparent font-bold"
            }
            activeClassName="border-blue-600 text-gray-900 hover:border-blue-600"
            to="/sobre-mim"
          >
            Saber mais
          </Link>

          <hr className="mt-8 border-b-2 border-b-slate-400" />

          <div className="p-4">
            <h2 className="text-xl mt-4 mb-4 font-bold">
              Alguns dos meus projetos
            </h2>
            <p className="mb-4">
              Fique a vontade para clicar nas fotos e ver mais detalhes sobre os
              projetos
            </p>
          </div>
          {shuffledPortfolioItems.slice(0, 6).map(portfolioItem => {
            return (
              <Link to={`/portfolio/${portfolioItem.slug}`}>
                <div className="mb-8">
                  <GatsbyImage
                    image={portfolioItem.thumbnail.gatsbyImageData}
                    alt={portfolioItem.name}
                  />
                  <p className="italic font-bold">{portfolioItem.name}</p>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default BlogPostSidebar
